import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import './SegView.css';

function SegView() {
  const location = useLocation();
  const { project } = location.state || {};

  const [activeSection, setActiveSection] = useState('projectDetails');

  useEffect(() => {
    if (!project) {
      return <div className="error-message">No project data available.</div>;
    }
  }, [project]);

  const handleSectionClick = (section) => {
  if (activeSection === section) {
    setActiveSection('');
  } else {
    setActiveSection(section);
  }
};

  return (
    <div className="seg-view-container" style={{width:'80%', margin:'auto'}}>
    <div style={{width:'100%', borderBottom:'1px solid grey', marginBottom:'10px', marginTop:'5px'}}>
      <h4 className="project-title" style={{ fontFamily: "'Montserrat', sans-serif"}}>
        Project: <span style={{color:'#2b5278', marginLeft:'10px'}}>{project.projectName}</span>
      </h4>
      </div>
      <div className="accordion">
        <div className="accordion-section">
         <button
          className={`accordion-button ${activeSection === 'projectDetails' ? 'active' : ''}`}
          onClick={() => handleSectionClick('projectDetails')}
          style={{ fontFamily: "'Montserrat', sans-serif"}}
        >
          PROJECT DETAILS
        </button>
          <div className={`accordion-content ${activeSection === 'projectDetails' ? 'active' : ''}`}>
            <table>
              <tbody>
                <tr>
                  <td className='tD1'>Segment Name:</td>
                  <td className='tD2'>
                  <span className='spanStyle'>{project.segment_name}</span></td>
                </tr>
                <tr>
                <td className='tD1'>Unit:</td>
                  <td className='tD2'>
                  <span className='spanStyle'>{project.unit}</span></td>
                </tr>
                <tr>
                <td className='tD1'>Year:</td>
                  <td className='tD2'>
                  <span className='spanStyle'>{project.year}</span></td>
                </tr>
                <tr>
                <td className='tD1'>Month:</td>
                  <td className='tD2'>
                  <span className='spanStyle'>{project.month}</span></td>
                </tr>
                <tr>
                <td className='tD1'>Job Number:</td>
                  <td className='tD2'>
                  <span className='spanStyle'>{project.jobNumber}</span></td>
                </tr>
                <tr>
                <td className='tD1'>Client:</td>
                  <td className='tD2'>
                  <span className='spanStyle'>{project.client}</span></td>
                </tr>
                <tr>
                <td className='tD1'>Segment Count:</td>
                  <td className='tD2'>
                  <span className='spanStyle'>{project.last_segment_number}</span></td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <div className="accordion-section">
          <button
            className={`accordion-button ${activeSection === 'researchAndAnalysis' ? 'active' : ''}`}
            onClick={() => handleSectionClick('researchAndAnalysis')}
            style={{ fontFamily: "'Montserrat', sans-serif"}}
          >
            RESEARCH AND ANALYSIS
          </button>
          <div className={`accordion-content ${activeSection === 'researchAndAnalysis' ? 'active' : ''}`}>
            <table>
              <tbody>
                <tr>
                <td className='tD1'>Research Group Head:</td>
                  <td className='tD2'>
                  <span className='spanStyle'>{project.research_group_head}</span></td>
                </tr>
                <tr>
                <td className='tD1'>Contact Person In Research:</td>
                  <td className='tD2'>
                  <span className='spanStyle'>{project.contact_person_in_research}</span></td>
                </tr>
                <tr>
                <td className='tD1'>Analysis Group Head:</td>
                  <td className='tD2'>
                  <span className='spanStyle'>{project.analysis_group_head}</span></td>
                </tr>
                <tr>
                <td className='tD1'>Contact Person In Analysis:</td>
                  <td className='tD2'>
                  <span className='spanStyle'>{project.contact_person_in_analysis}</span></td>
                </tr>
                <tr>
                <td className='tD1'>Actual Sample Size:</td>
                  <td className='tD2'>
                  <span className='spanStyle'>{project.actual_sample_size}</span></td>
                </tr>
                <tr>
                <td className='tD1'>Achieved Sample Size:</td>
                  <td className='tD2'>
                  <span className='spanStyle'>{project.achieved_sample_size}</span></td>
                </tr>
                <tr>
                <td className='tD1'>Final Processed Samples:</td>
                  <td className='tD2'>
                  <span className='spanStyle'>{project.final_processed_samples}</span></td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <div className="accordion-section">
          <button
            className={`accordion-button ${activeSection === 'timeline' ? 'active' : ''}`}
            onClick={() => handleSectionClick('timeline')}
            style={{ fontFamily: "'Montserrat', sans-serif"}}
          >
            TIMELINE
          </button>
          <div className={`accordion-content ${activeSection === 'timeline' ? 'active' : ''}`}>
            <table>
              <tbody>
                <tr>
                  <td className='tD1'>Expected Date of Output:</td>
                  <td className='tD2'>
                  <span className='spanStyle'>{project.expectedDateOfOutput}</span></td>
                </tr>
                <tr>
                  <td className='tD1'>Actual Final Output:</td>
                  <td className='tD2'>
                  <span className='spanStyle'>{project.actual_final_output}</span></td>
                </tr>
                <tr>
                  <td className='tD1'>Date Of AWN:</td>
                  <td className='tD2'>
                  <span className='spanStyle'>{project.date_of_awn}</span></td>
                </tr>
                <tr>
                  <td className='tD1'>Date Of R-AWN1:</td>
                  <td className='tD2'>
                  <span className='spanStyle'>{project.date_of_r_awn1}</span></td>
                </tr>
                <tr>
                  <td className='tD1'>Final R-AWN Date:</td>
                  <td className='tD2'>
                  <span className='spanStyle'>{project.final_r_awn}</span></td>
                </tr>
                <tr>
                  <td className='tD1'>Questionnaire Received Date:</td>
                  <td className='tD2'>
                  <span className='spanStyle'>{project.questionnaire_received_date}</span></td>
                </tr>
                <tr>
                  <td className='tD1'>AP Received Date:</td>
                  <td className='tD2'>
                  <span className='spanStyle'>{project.ap_received_date}</span></td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <div className="accordion-section">
          <button
            className={`accordion-button ${activeSection === 'advancedAnalytics' ? 'active' : ''}`}
            onClick={() => handleSectionClick('advancedAnalytics')}
            style={{ fontFamily: "'Montserrat', sans-serif"}}
          >
            ADVANCED ANALYTICS
          </button>
          <div className={`accordion-content ${activeSection === 'advancedAnalytics' ? 'active' : ''}`}>
            <table>
              <tbody>
                <tr>
                  <td className='tD1'>Advanced Analytics:</td>
                  <td className='tD2'>
                  <span className='spanStyle'>{project.advanced_analytics}</span></td>
                </tr>
                <tr>
                  <td className='tD1'>FW Start Date:</td>
                  <td className='tD2'>
                  <span className='spanStyle'>{project.fw_start_date}</span></td>
                </tr>
                <tr>
                  <td className='tD1'>FW End Date:</td>
                  <td className='tD2'>
                  <span className='spanStyle'>{project.fw_end_date}</span></td>
                </tr>
                <tr>
                  <td className='tD1'>Top Lines:</td>
                  <td className='tD2'>
                  <span className='spanStyle'>{project.top_lines}</span></td>
                </tr>
                <tr>
                  <td className='tD1'>Top Lines Date:</td>
                  <td className='tD2'>
                  <span className='spanStyle'>{project.top_lines_date}</span></td>
                </tr>
                <tr>
                  <td className='tD1'>OE Coding:</td>
                  <td className='tD2'>
                  <span className='spanStyle'>{project.oe_coding}</span></td>
                </tr>
                <tr>
                  <td className='tD1'>OE Costing:</td>
                  <td className='tD2'>
                  <span className='spanStyle'>{project.oe_costing}</span></td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <div className="accordion-section">
          <button
            className={`accordion-button ${activeSection === 'platforms' ? 'active' : ''}`}
            onClick={() => handleSectionClick('platforms')}
            style={{ fontFamily: "'Montserrat', sans-serif"}}
          >
            PLATFORMS
          </button>
          <div className={`accordion-content ${activeSection === 'platforms' ? 'active' : ''}`}>
            <table>
              <tbody>
                <tr>
                  <td className='tD1'>Survey Platform:</td>
                  <td className='tD2'>
                  <span className='spanStyle'>{project.survey_platform}</span></td>
                </tr>
                <tr>
                  <td className='tD1'>DP Platform:</td>
                  <td className='tD2'>
                  <span className='spanStyle'>{project.dp_platform}</span></td>
                </tr>
                <tr>
                  <td className='tD1'>Number Of Response:</td>
                  <td className='tD2'>
                  <span className='spanStyle'>{project.number_of_response}</span></td>
                </tr>
                <tr>
                  <td className='tD1'>Data Correction Done:</td>
                  <td className='tD2'>
                  <span className='spanStyle'>{project.data_correction_done}</span></td>
                </tr>
                <tr>
                  <td className='tD1'>Norms Done:</td>
                  <td className='tD2'>
                  <span className='spanStyle'>{project.norms_done}</span></td>
                </tr>
                <tr>
                  <td className='tD1'>Project Classification:</td>
                  <td className='tD2'>
                  <span className='spanStyle'>{project.project_classification}</span></td>
                </tr>
                <tr>
                  <td className='tD1'>Feedback Survey Done:</td>
                  <td className='tD2'>
                  <span className='spanStyle'>{project.feedback_survey_done}</span></td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <div className="accordion-section">
          <button
            className={`accordion-button ${activeSection === 'status' ? 'active' : ''}`}
            onClick={() => handleSectionClick('status')}
            style={{ fontFamily: "'Montserrat', sans-serif"}}
          >
           STATUS
          </button>
          <div className={`accordion-content ${activeSection === 'status' ? 'active' : ''}`}>
            <table>
              <tbody>
                <tr>
                  <td className='tD1'>Status:</td>
                  <td className='tD2'>
                  <span className='spanStyle'>{project.status}</span></td>
                </tr>
                <tr>
                  <td className='tD1'>LOI:</td>
                  <td className='tD2'>
                  <span className='spanStyle'>{project.loi}</span></td>
                </tr>
                <tr>
                  <td className='tD1'>Check List Followed:</td>
                  <td className='tD2'>
                  <span className='spanStyle'>{project.check_list_followed}</span></td>
                </tr>
                <tr>
                  <td className='tD1'>Data Correction Done:</td>
                  <td className='tD2'>
                  <span className='spanStyle'>{project.data_correction_done}</span></td>
                </tr>
                <tr>
                  <td className='tD1'>Completed Month:</td>
                  <td className='tD2'>
                  <span className='spanStyle'>{project.completed_month}</span></td>
                </tr>
                <tr>
                  <td className='tD1'>Archive Taken:</td>
                  <td className='tD2'>
                  <span className='spanStyle'>{project.archive_taken}</span></td>
                </tr>
                <tr>
                  <td className='tD1'>Remarks:</td>
                  <td className='tD2'>
                  <span className='spanStyle'>{project.remarks}</span></td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

      </div>
    </div>
  );
}

export default SegView;