import React, { useState, useEffect } from 'react';
import './Navibar.css';
import Navibar2 from './Navibar2.js';
import Segments from './Segments.js';
import { useLocation } from 'react-router-dom';

const NaviBar = () => {
  const location = useLocation();
  const [activeIndex, setActiveIndex] = useState(-1);
  const [inputValues1, setInputValues] = useState(['', '', '', '', '', '']);
  const [projectNames, setProjectNames] = useState([]);
  const [projectNameExists, setProjectNameExists] = useState(false);
  const allFieldsFilled = inputValues1.every((value) => value !== '');
  const months = [
    'January', 'February', 'March', 'April', 'May', 'June',
    'July', 'August', 'September', 'October', 'November', 'December'
  ];
  const items = [
    'UNIT', 'YEAR', 'MONTH', 'PROJECT NAME', 'JOB NUMBER', 'CLIENT'
  ];
  const unitOptions = ['4SIGHT UAE', '4SIGHT KSA'];

  const handleItemClick = (index) => {
    setActiveIndex(index);
  };

  const handleInputChange = (e, index) => {
    const newValue = e.target.value;

    if (items[index] === 'YEAR' && isNaN(newValue)) {
      return;
    }
    if (items[index] === 'YEAR' && newValue.length > 4) {
      return;
    }
    if (items[index] === 'JOB NUMBER') {
      if (newValue.length <= 2) {
        if (!/^[A-Za-z]*$/.test(newValue)) {
          return;
        }
      } else {
        if (!/^\d+$/.test(newValue.slice(2))) {
          return;
        }
      }
    }

    const newInputValues = [...inputValues1];
    newInputValues[index] = newValue;
    setInputValues(newInputValues);

    if (index === 3) {
      checkProjectNameExists(newValue);
    }
  };

  const checkProjectNameExists = (projectName) => {
    const existingProjectName = projectNames.find((project) => project.projectName === projectName);
    if (existingProjectName) {
      setProjectNameExists(true);
    } else {
      setProjectNameExists(false);
    }
  };

  useEffect(() => {
    const fetchProjectNames = async () => {
      try {
        const response = await fetch('http://localhost:8000/api/project-names/');
        if (!response.ok) throw new Error('Network response was not ok');
        const data = await response.json();
        const projectNames = data.map(([projectName, jobNumber, unit, year, month, last_segment_number ]) => ({ projectName, jobNumber,
           unit, month, year, last_segment_number}));
        setProjectNames(projectNames);
      } catch (error) {
        console.error('Error fetching project names:', error);
      }
    };

    fetchProjectNames();
  }, []);

  useEffect(() => {
    if (location.state?.inputValues) {
      setInputValues(location.state.inputValues);
    }
  }, [location.state]);

  useEffect(() => {
    if (inputValues1.every((value, index) => {
      if (index === 1) { 
        return value !== null && value !== undefined;
      } else if (index === 2) {
        return typeof value === 'string' && value.trim() !== '';
      } else {
        return typeof value === 'string' && value.trim() !== '';
      }
    }) && !projectNameExists) {
      const data = inputValues1.map((value, index) => `${items[index]}: ${value}`);
      sendToBackend(data);
    }
  }, [inputValues1, projectNameExists]);

  const sendToBackend = (data) => {
    fetch('your-backend-url', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data)
    })
    .then(response => {
      if (!response.ok) {
        throw new Error('Failed to send data to backend');
      }
      console.log('Data successfully sent to backend');
    })
    .catch(error => {
      console.error('Error:', error);
    });
  };

  return (
    <div>
        {/* <div style={{display:'flex', justifyContent:'center', backgroundColor:'#276B', color:'white'}}>
        <h3 style={{fontFamily: 'Roboto, sans-serif'}}><strong>Job Tracker</strong></h3>
    </div> */}
      <div className="navbar">
        {items.map((item, index) => (
          <React.Fragment key={index}>
            {activeIndex === index ? (
              <div className="form-controll">
                {item === 'MONTH' ? (
                  <select
                    className="form-controll form-inputt"
                    value={inputValues1[index]}
                    onChange={(e) => handleInputChange(e, index)}
                    onBlur={() => setActiveIndex(-1)}
                    autoFocus
                    required
                    style={{padding:'5px'}}
                  >
                    <option value="" hidden>Select Month</option>
                    {months.map((month, i) => (
                      <option key={i} value={month}>{month}</option>
                    ))}
                  </select>
                ) : item === 'UNIT' ? (
                  <select
                    className="form-controll form-inputt"
                    value={inputValues1[index]}
                    onChange={(e) => handleInputChange(e, index)}
                    onBlur={() => setActiveIndex(-1)}
                    autoFocus
                    required
                    style={{padding:'5px'}}
                  >
                    <option value="" hidden>Select Unit</option>
                    {unitOptions.map((unit, i) => (
                      <option key={i} value={unit}>{unit}</option>
                    ))}
                  </select>
                ) : (
                  <input
                    type="text"
                    className="form-controll form-inputt"
                    value={inputValues1[index]}
                    onChange={(e) => handleInputChange(e, index)}
                    onBlur={() => setActiveIndex(-1)}
                    autoFocus
                    required
                  />
                )}
                <label>
                  {/* {item.split('').map((char, i) => (
                    <span key={i} style={{ transitionDelay: `${i * 5}ms` }}>
                      {char}
                    </span>
                  ))} */}
                </label>
              </div>
            ) : (
              <button
                onClick={() => handleItemClick(index)}
                className="btn btn-outline-secondary sidebar-button"
              >
                {inputValues1[index] ? `${item}: ${inputValues1[index]}` : `${item}:`}
              </button>
            )}
          </React.Fragment>
        ))}
      </div>
      <Segments inputValues1={inputValues1} allFieldsFilled={allFieldsFilled} disabled={projectNameExists} />
    </div>
  );
};

export default NaviBar;