import React, { useState, useEffect } from 'react';
import './Segments.css';
import Navibar2 from './Navibar2';
import { useNavigate, useLocation } from 'react-router-dom';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import moment from 'moment';

const Dropdown = ({ options, onSelect, item, currentSegment, selectedDropdownValues, inputValuesState }) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleSelect = (option) => {
    onSelect(option);
    setIsOpen(false);
  };

  const selectedValue = inputValuesState[currentSegment]?.[item] || selectedDropdownValues[currentSegment]?.[item] || '';

  return (
    <div className="custom-dropdown5">
      <select
        className="custom-dropdown5-toggle form-control1"
        value={selectedValue}
        onChange={(e) => handleSelect(e.target.value)}
        onBlur={() => setIsOpen(false)}
        autoFocus
        required
        style={{width:'180px'}}
      >
        <option value="" hidden></option>
        {options.map((option, index) => (
          <option key={index} value={option}>{option}</option>
        ))}
      </select>
    </div>
  );
};

const Segments = ({ inputValues1, isEditing = false, allFieldsFilled, disabled  }) => {
  const location = useLocation();
  const [dateState, setDateState] = useState({});
  const inputValuesState1 = inputValues1 || location.state?.inputValuesState || [];
  const segments = location.state?.segments || [];
  const [segmentCount, setSegmentCount] = useState(segments.length || 0);
  const [currentSegment, setCurrentSegment] = useState(0);
  const [submitted, setSubmitted] = useState(false);
  const [activeIndex, setActiveIndex] = useState(-1);
  const [dropdownOptions, setDropdownOptions] = useState({
    'SURVEY PLATFORM': ['S2C', 'STG', 'FORSTA'],
    'DP PLATFORM': ['SPSS', 'DIMENSIONS', 'Q RESEARCH TOOL', 'QUANTUM'],
    'PROJECT CLASSIFICATION': ['CLASS A', 'CLASS B', 'CLASS C', 'CLASS D'],
    'RESEARCH GROUP HEAD': ['ELIAS', 'KUNAL', 'RAHUL', 'MANOJ IYER', 'ASHISH'],
    'CONTACT PERSON IN RESEARCH': ['ANANYA', 'PULKIT', 'PRIYA', 'SARANYA', 'MANOJ KUMAR', 'ARUNA','VINIVAA', 'SONAL',
      'CAROLINE MAINA', 'KRATI'
    ],
    'ANALYSIS GROUP HEAD': ['RAMAMOORTHY', 'SATHISH KUMAR'],
    'CONTACT PERSON IN ANALYSIS': ['ANURADHA', 'VARUN', 'SRIVENDHAN', 'RAGHU RAM', 'SARAVANA KUMAR'],
    'ADVANCED ANALYTICS': ['MAXDIFF', 'DRIVER ANALYSIS', 'CORRELATION', 'JACCARD', 'CHI SQUARE'],
    'CHECK LIST FOLLOWED':['YES', 'NO'],
    'FEEDBACK SURVEY DONE':['YES', 'NO'], 
    'DATA CORRECTION DONE':['YES', 'NO'],
    'COMPLETED MONTH': [
    'JANUARY', 'FEBRUARY', 'MARCH', 'APRIL', 'MAY', 'JUNE', 
    'JULY', 'AUGUST', 'SEPTEMBER', 'OCTOBER', 'NOVEMBER', 'DECEMBER'
  ],
  'NORMS DONE':['YES', 'NO'],
  'STATUS':['OPEN', 'CLOSE', 'HOLD', 'REOPEN', 'RECLOSE']
  });
  const [inputValuesState, setInputValues] = useState(segments || []);
  const [originalDropdownValues, setOriginalDropdownValues] = useState({});
  const mandatoryFields = [
    'EXPECTED DATE OF OUTPUT',
    'ACTUAL FINAL OUTPUT',
    'SEGMENT NAME',
    'COMPLETED MONTH',
    'DATA CORRECTION DONE',
    'STATUS',
    'RESEARCH GROUP HEAD',
    'CONTACT PERSON IN RESEARCH',
    'ANALYSIS GROUP HEAD',
    'CONTACT PERSON IN ANALYSIS',
    'DATA CORRECTION DONE',
    'CHECK LIST FOLLOWED',
    'FEEDBACK SURVEY DONE',
  ];
  const items = [
    'SEGMENT NAME',
    'ACTUAL SAMPLE SIZE',
    'ACHIEVED SAMPLE SIZE',
    'FINAL PROCESSED SAMPLES',
    'RESEARCH GROUP HEAD',
    'CONTACT PERSON IN RESEARCH',
    'ANALYSIS GROUP HEAD',
    'CONTACT PERSON IN ANALYSIS',
    'DATE OF AWN',
    'DATE OF R-AWN1',
    'FINAL R-AWN DATE',
    'QUESTIONNAIRE RECEIVED DATE',
    'AP RECEIVED DATE',
    'FW START DATE',
    'FW END DATE',
    'ADVANCED ANALYTICS',
    'EXPECTED DATE OF OUTPUT',
    'ACTUAL FINAL OUTPUT',
    'TOP LINES',
    'TOP LINES DATE',
    'OE CODING',
    'OE COSTING',
    'NUMBER OF RESPONSE',
    'DATA CORRECTION DONE',
    'NORMS DONE',
    'SURVEY PLATFORM',
    'DP PLATFORM',
    'PROJECT CLASSIFICATION',
    'STATUS',
    'LOI',
    'CHECK LIST FOLLOWED',
    'FEEDBACK SURVEY DONE',
    'COMPLETED MONTH',
    'ARCHIVE TAKEN',
    'REMARKS'
  ];

  const navigate = useNavigate();

  useEffect(() => {
    if (segments && segments.length > 0) {
      const initialInputValues = segments.map(segment => ({
        'SEGMENT NAME': segment.segment_name || '',
        'ACTUAL SAMPLE SIZE': segment.actual_sample_size || '',
        'ACHIEVED SAMPLE SIZE': segment.achieved_sample_size || '',
        'FINAL PROCESSED SAMPLES': segment.final_processed_samples || '',
        'RESEARCH GROUP HEAD': segment.research_group_head || '',
        'CONTACT PERSON IN RESEARCH': segment.contact_person_in_research || '',
        'ANALYSIS GROUP HEAD': segment.analysis_group_head || '',
        'CONTACT PERSON IN ANALYSIS': segment.contact_person_in_analysis || '',
        'DATE OF AWN': moment(segment.date_of_awn, 'DD/MM/YYYY').toDate(),
      'DATE OF R-AWN1': moment(segment.date_of_r_awn1, 'DD/MM/YYYY').toDate(),
      'FINAL R-AWN DATE': moment(segment.final_r_awn, 'DD/MM/YYYY').toDate(),
      'TOP LINES DATE': moment(segment.top_lines_date, 'DD/MM/YYYY').toDate(),
      'QUESTIONNAIRE RECEIVED DATE': moment(segment.questionnaire_received_date, 'DD/MM/YYYY').toDate(),
      'AP RECEIVED DATE': moment(segment.ap_received_date, 'DD/MM/YYYY').toDate(),
      'FW START DATE': moment(segment.fw_start_date, 'DD/MM/YYYY').toDate(),
      'FW END DATE': moment(segment.fw_end_date, 'DD/MM/YYYY').toDate(),
      'EXPECTED DATE OF OUTPUT': moment(segment.expected_date_of_output, 'DD/MM/YYYY').toDate(),
      'ACTUAL FINAL OUTPUT': moment(segment.actual_final_output, 'DD/MM/YYYY').toDate(),
        'ADVANCED ANALYTICS': segment.advanced_analytics || '',
        'TOP LINES': segment.top_lines || '',
        'OE CODING': segment.oe_coding || '',
        'OE COSTING': segment.oe_costing || '',
        'NUMBER OF RESPONSE': segment.number_of_response || '',
        'DATA CORRECTION DONE': segment.data_correction_done || '',
        'NORMS DONE': segment.norms_done || '',
        'SURVEY PLATFORM': segment.survey_platform || '',
        'DP PLATFORM': segment.dp_platform || '',
        'PROJECT CLASSIFICATION': segment.project_classification || '',
        'STATUS': segment.status || '',
        'LOI': segment.loi || '',
        'CHECK LIST FOLLOWED': segment.check_list_followed || '',
        'FEEDBACK SURVEY DONE': segment.feedback_survey_done || '',
        'COMPLETED MONTH': segment.completed_month || '',
        'ARCHIVE TAKEN': segment.archive_taken || '',
        'REMARKS': segment.remarks || ''
      }));
      const initialOriginalDropdownValues = segments.map((segment, index) => {
        const dropdownValues = {};
        Object.keys(dropdownOptions).forEach((key) => {
          if (segment[key]) {
            dropdownValues[key] = segment[key];
          }
        });
        return dropdownValues;
      });
      setOriginalDropdownValues(initialOriginalDropdownValues);
      setInputValues(initialInputValues);
    } else if (inputValuesState.length > 0) {
      setInputValues(inputValuesState.map((value) => ({
          SEGMENT_NAME: value[0],
          YEAR: value[1],
          MONTH: value[2],
          PROJECT_NAME: value[3],
          JOB_NUMBER: value[4],
          CLIENT: value[5],
      })));
    }
    else{
      const initialOriginalDropdownValues = Array(segmentCount).fill({});
    setOriginalDropdownValues(initialOriginalDropdownValues);
    }
  }, [segments, inputValuesState, segmentCount]);

  useEffect(() => {
    if (currentSegment < segmentCount) {
      const storedSegmentData = localStorage.getItem(`segment_${currentSegment}`);
      if (storedSegmentData) {
        const segmentValues = JSON.parse(storedSegmentData);
        const updatedInputValues = [...inputValuesState];
        updatedInputValues[currentSegment] = segmentValues;
        setInputValues(updatedInputValues);
      }
    }
  }, [currentSegment, segmentCount, inputValuesState]);

  useEffect(() => {
    setInputValues(Array.from({ length: segmentCount }, () => ({})));
  }, [segmentCount]);

  const [selectedDropdownValues, setSelectedDropdownValues] = useState(Array(segmentCount).fill({}));

  useEffect(() => {
    setSelectedDropdownValues(Array.from({ length: segmentCount }, () => ({})));
}, [segmentCount]);

  const clearInputValues = () => {
    console.log('clearInputValues called');
    const newInputValues = [...inputValuesState];
    if (currentSegment < newInputValues.length) {
    newInputValues[currentSegment] = {};
    setInputValues(newInputValues);
    }
    setDateState({});
    const updatedSelectedDropdownValues = [...selectedDropdownValues];
    updatedSelectedDropdownValues[currentSegment] = {};
    setSelectedDropdownValues(updatedSelectedDropdownValues);
  };

  const handleSegmentCountChange = (newCount) => {
    if (newCount >= 0) {
      setSegmentCount(newCount);
      setCurrentSegment(0);
      setSubmitted(false);
      setInputValues([]);
      setDateState({});
      setSelectedDropdownValues([]);
      const initialOriginalDropdownValues = Array(newCount).fill({});
      setOriginalDropdownValues(initialOriginalDropdownValues);
      for (let segmentIndex = 0; segmentIndex < segmentCount; segmentIndex++) {
        localStorage.removeItem(`segment_${segmentIndex}`);
      }
    }
  };

  const handleNextClick = () => {
    if (currentSegment < segmentCount && inputValuesState.length > currentSegment) {
      const oeFieldsFilled = inputValuesState[currentSegment]['OE CODING'] && inputValuesState[currentSegment]['OE COSTING'];
      
      if (oeFieldsFilled) {
        mandatoryFields.push('NUMBER OF RESPONSE');
      }
      
      let allInputsFilled;
      if (isEditing) {
        allInputsFilled = mandatoryFields.every((item) => {
          if ([
            'SURVEY PLATFORM',
            'DP PLATFORM',
            'PROJECT CLASSIFICATION',
            'RESEARCH GROUP HEAD',
            'CONTACT PERSON IN RESEARCH',
            'ANALYSIS GROUP HEAD',
            'CONTACT PERSON IN ANALYSIS',
            'ADVANCED ANALYTICS',
            'CHECK LIST FOLLOWED',
            'FEEDBACK SURVEY DONE',
            'DATA CORRECTION DONE',
            'COMPLETED MONTH',
            'NORMS DONE',
            'STATUS'
          ].includes(item)) {
            return inputValuesState[currentSegment][item] !== undefined && inputValuesState[currentSegment][item] !== '';
          } else if (typeof inputValuesState[currentSegment][item] === 'string') {
            return inputValuesState[currentSegment][item] !== undefined && inputValuesState[currentSegment][item].trim() !== '';
          } else {
            return inputValuesState[currentSegment][item] !== undefined && inputValuesState[currentSegment][item] !== null;
          }
        });
      } else {
        const currentSegmentInputs = [
          'EXPECTED DATE OF OUTPUT',
          'ACTUAL FINAL OUTPUT',
          'SEGMENT NAME',
          'COMPLETED MONTH',
          'DATA CORRECTION DONE',
          'STATUS',
          'RESEARCH GROUP HEAD',
          'CONTACT PERSON IN RESEARCH',
          'ANALYSIS GROUP HEAD',
          'CONTACT PERSON IN ANALYSIS',
          'DATA CORRECTION DONE',
          'CHECK LIST FOLLOWED',
          'FEEDBACK SURVEY DONE',
        ];
      
        if (oeFieldsFilled) {
          currentSegmentInputs.push('NUMBER OF RESPONSE');
        }
      
        allInputsFilled = currentSegmentInputs.every((item) => {
          if ([
            'SURVEY PLATFORM',
            'DP PLATFORM',
            'PROJECT CLASSIFICATION',
            'RESEARCH GROUP HEAD',
            'CONTACT PERSON IN RESEARCH',
            'ANALYSIS GROUP HEAD',
            'CONTACT PERSON IN ANALYSIS',
            'ADVANCED ANALYTICS',
            'CHECK LIST FOLLOWED',
            'FEEDBACK SURVEY DONE',
            'DATA CORRECTION DONE',
            'COMPLETED MONTH',
            'NORMS DONE',
            'STATUS'
   ].includes(item)) {
            return inputValuesState[currentSegment][item] !== undefined && inputValuesState[currentSegment][item] !== '';
          } else if (typeof inputValuesState[currentSegment][item] === 'string') {
            return inputValuesState[currentSegment][item] !== undefined && inputValuesState[currentSegment][item].trim() !== '';
          } else {
            return inputValuesState[currentSegment][item] !== undefined && inputValuesState[currentSegment][item] !== null;
          }
        });
      }
      
      if (allInputsFilled) {
        const newInputValues = [...inputValuesState];
        newInputValues[currentSegment] = { ...inputValuesState[currentSegment] };
      
        // Update the inputValuesState
        setInputValues(newInputValues);
      
        const newSegmentIndex = currentSegment + 1;
        const newSegmentData = inputValuesState[newSegmentIndex];
      
        const dateFields = [
          'DATE OF AWN',
          'DATE OF R-AWN1',
          'FINAL R-AWN DATE',
          'TOP LINES DATE',
          'QUESTIONNAIRE RECEIVED DATE',
          'AP RECEIVED DATE',
          'FW START DATE',
          'FW END DATE',
          'EXPECTED DATE OF OUTPUT',
          'ACTUAL FINAL OUTPUT',
        ];
      
        dateFields.forEach((field) => {
          if (newSegmentData[field]) {
            setDateState((prevDateState) => ({ ...prevDateState, [field]: newSegmentData[field] }));
          }
        });
      
        // Remove the clearInputValues function call
        console.log('handleNextClick: clearInputValues called');
        clearInputValues();
      
        if (currentSegment < segmentCount - 1) {
          setCurrentSegment((prev) => prev + 1);
          setInputValues((prev) => {
            const newState = [...prev];
            newState[currentSegment] = {}; 
            return newState;
          });
          setSelectedDropdownValues((prev) => {
            const newState = [...prev];
            newState[currentSegment] = {}; 
            newState[currentSegment + 1] = {}; // Reset the selectedDropdownValues for the next segment
            return newState;
          });
          // Reset the selectedDropdownValues for the current segment
          const updatedSelectedDropdownValues = [...selectedDropdownValues];
    updatedSelectedDropdownValues[currentSegment] = { ...inputValuesState[currentSegment] }; // Update the selectedDropdownValues with the current values
    updatedSelectedDropdownValues[currentSegment + 1] = {}; // Reset the selectedDropdownValues for the next segment
    setSelectedDropdownValues(updatedSelectedDropdownValues);
    
    const updatedOriginalDropdownValues = [...originalDropdownValues];
    updatedOriginalDropdownValues[currentSegment] = { ...inputValuesState[currentSegment] }; // Update the originalDropdownValues with the current values
    setOriginalDropdownValues(updatedOriginalDropdownValues);
        } else {
          alert('You are already on the last segment.');
        }
      } else {
        alert('Please Fill the  Required Fields');
      }
    }
  };
  
  const clearStoredData = () => {
    for (let segmentIndex = 0; segmentIndex < segmentCount; segmentIndex++) {
      localStorage.removeItem(`segment_${segmentIndex}`);
    }
  };
  window.onbeforeunload = clearStoredData;

  const handleDropdownSelect = (item, option) => {
    const updatedInputValues = [...inputValuesState];
    if (!updatedInputValues[currentSegment]) {
      updatedInputValues[currentSegment] = {};
    }
    
    updatedInputValues[currentSegment][item] = option;
    setInputValues(updatedInputValues);
    
    // Update selectedDropdownValues for the current segment
    const updatedSelectedDropdownValues = [...selectedDropdownValues];
    updatedSelectedDropdownValues[currentSegment] = {
      ...updatedSelectedDropdownValues[currentSegment],
      [item]: option
    };
    
    setSelectedDropdownValues(updatedSelectedDropdownValues);
    
    // Store in local storage
    localStorage.setItem(`segment_${currentSegment}`, JSON.stringify(updatedInputValues[currentSegment]));
  };

  const handleInputChange = (event, item) => {
    let value;
    if (event.target) {
      value = event.target.value;
    } else {
      value = event; 
    }
    
    const updatedInputValues = [...inputValuesState];
    updatedInputValues[currentSegment] = { 
      ...updatedInputValues[currentSegment], 
      [item]: item.includes('DATE') ? (value instanceof Date ? value : moment(value, 'DD/MM/YYYY').toDate()) : value
    };
    
    setInputValues(updatedInputValues);
    setDateState((prevDateState) => ({ ...prevDateState, [item]: value }));
    
    // Update stored values in local storage
    const formattedInputValues = { ...updatedInputValues[currentSegment] };
    localStorage.setItem(`segment_${currentSegment}`, JSON.stringify(formattedInputValues));
  };
  

  const handleSubmission = () => {
    setSubmitted(true);
  }

  const handleSubmission1 = () => {
    const dataToSend = [];
    dataToSend.push({
      Unit: inputValues1[0],
      Year: inputValues1[1],
      Month: inputValues1[2],
      ProjectName: inputValues1[3],
      JobNumber: inputValues1[4],
      Client: inputValues1[5],
      segmentCount: segmentCount
    });
  
    const updatedInputValues = [...inputValuesState];
  
    for (let segmentIndex = 0; segmentIndex < segmentCount; segmentIndex++) {
      const storedSegmentData = localStorage.getItem(`segment_${segmentIndex}`);
      if (storedSegmentData) {
        const segmentValues = JSON.parse(storedSegmentData);
        Object.entries(segmentValues).forEach(([item, value]) => {
          if (Array.isArray(value)) {
            dataToSend.push({
              SegmentNumber: segmentIndex + 1,
              item,
              value: dropdownOptions[item][value],
              selectedDropdownValue: value,
              projectName: inputValues1[3]
            });
          } else if (value instanceof Date) {
            const date = moment(value);
            const formattedDate = date.format('DD/MM/YYYY');
            dataToSend.push({
              segmentNumber: segmentIndex + 1,
              item,
              value: formattedDate,
              projectName: inputValues1[3]
            });
          } else if (typeof value === 'string' && moment(value, 'YYYY-MM-DDTHH:mm:ss.SSSZ', true).isValid()) {
            const date = moment(value);
            const formattedDate = date.format('DD/MM/YYYY');
            dataToSend.push({
              segmentNumber: segmentIndex + 1,
              item,
              value: formattedDate,
              projectName: inputValues1[3]
            });
          } else {
            dataToSend.push({
              segmentNumber: segmentIndex + 1,
              item,
              value,
              projectName: inputValues1[3]
            });
          }
        });
      } else {
        const segmentValues = updatedInputValues[segmentIndex];
        Object.entries(segmentValues).forEach(([item, value]) => {
          if (Array.isArray(value)) {
            dataToSend.push({
              SegmentNumber: segmentIndex + 1,
              item,
              value: dropdownOptions[item][value],
              selectedDropdownValue: value,
              projectName: inputValues1[3]
            });
          } else if (value instanceof Date) {
            const date = moment(value);
            const formattedDate = date.format('DD/MM/YYYY');
            dataToSend.push({
              segmentNumber: segmentIndex + 1,
              item,
              value: formattedDate,
              projectName: inputValues1[3]
            });
          } else if (typeof value === 'string' && moment(value, 'YYYY-MM-DDTHH:mm:ss.SSSZ', true).isValid()) {
            const date = moment(value);
            const formattedDate = date.format('DD/MM/YYYY');
            dataToSend.push({
              segmentNumber: segmentIndex + 1,
              item,
              value: formattedDate,
              projectName: inputValues1[3]
            });
          } else {
            dataToSend.push({
              segmentNumber: segmentIndex + 1,
              item,
              value,
              projectName: inputValues1[3]
            });
          }
        });
      }
    }
  
    setInputValues(updatedInputValues);

    const nextSegmentIndex = currentSegment + 1;
    if (nextSegmentIndex < segmentCount) {
      setSelectedDropdownValues(prev => {
        const newState = [...prev];
        newState[nextSegmentIndex] = {}; // Clear values for the next segment
        return newState;
      });
    }
  
    navigate('/conclusion');
    clearStoredData();
  
    fetch('http://localhost:8000/api/segments/', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(dataToSend)
    })
    .then(response => {
      if (response.ok) {
        console.log('Data sent successfully');
        for (let segmentIndex = 0; segmentIndex < segmentCount; segmentIndex++) {
          localStorage.removeItem(`segment_${segmentIndex}`);
        }
        // Send a PATCH request to update the segments
        updateSegmentsInBackend(dataToSend);
      } else {
        console.error('Error:', response.statusText);
      }
    })
    .catch(error => {
      console.error('Error:', error);
    });

  
  const updateSegmentsInBackend = (dataToSend) => {
  const projectName = dataToSend[0].ProjectName;
  const segmentData = dataToSend.slice(1);

  const url = `http://localhost:8000/api/segments/${projectName}/`;

  fetch(url, {
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(segmentData)
  })
  .then(response => {
    if (response.ok) {
      console.log('Segments updated successfully');
    } else {
      console.error('Error updating segments:', response.statusText);
    }
  })
  .catch(error => {
    console.error('Error updating segments:', error);
  });
};
}

const handlePreviousSegment = () => {
  const newSegmentIndex = currentSegment - 1;
  setCurrentSegment(newSegmentIndex);
  setInputValues((prev) => {
    const newState = [...prev];
    newState[newSegmentIndex] = { ...originalDropdownValues[newSegmentIndex] }; // Update the inputValuesState with the original values
    return newState;
  });
  setSelectedDropdownValues((prev) => {
    const newState = [...prev];
    newState[newSegmentIndex] = { ...originalDropdownValues[newSegmentIndex] }; // Update the selectedDropdownValues with the original values
    newState[newSegmentIndex + 1] = {}; // Reset the selectedDropdownValues for the next segment
    return newState;
  });
  setDateState({});
};

  return (
    <div>
      <Navibar2 segmentCount={segmentCount} onSegmentCountChange={handleSegmentCountChange}
        onSubmit={handleSubmission} isEditing={location.state?.isEditing} allFieldsFilled={allFieldsFilled} 
        disabled={disabled} />
      {submitted && (
        <div>
          {Array.from({ length: segmentCount }).map((_, segmentIndex) => (
            <div key={segmentIndex} className={`segment-content ${segmentIndex === currentSegment ?
             'active1' : ''}`} style={{ display: segmentIndex === currentSegment ? 'block' : 'none' }}>
              <div className="card2">
                <h5 style={{display:'flex', justifyContent:'center', fontFamily: 'Roboto, sans-serif'}}>
                Segment {segmentIndex + 1}:</h5>
                <hr />
                <div className="variable-columns2">
                {[...Array(Math.ceil(items.length / 10))].map((_, columnIndex) => (
                  <ul key={columnIndex} className="variable-list2">
                    {items.slice(columnIndex * 12, (columnIndex + 1) * 12).map((item, index) => (
                      <li key={index}>
                        <label style={{ fontSize: '0.7rem' }}>{item}:</label>
                        <div style={{ position: 'relative' }}>
                          {item === 'DATE OF AWN' || item === 'DATE OF R-AWN1' || item === 'FINAL R-AWN DATE'
                          || item === 'TOP LINES DATE' || item === 'QUESTIONNAIRE RECEIVED DATE' 
                          || item === 'AP RECEIVED DATE' || item === 'FW START DATE'
                          || item === 'FW END DATE' || item === 'EXPECTED DATE OF OUTPUT' ||
                           item === 'ACTUAL FINAL OUTPUT'  ? (
                            <DatePicker
                              selected={dateState[item] || (inputValuesState[currentSegment][item] && moment(inputValuesState[currentSegment][item]).isValid() ? moment(inputValuesState[currentSegment][item]).toDate() : null)}
                              onChange={(date) => handleInputChange(date, item)}
                              onKeyDown={(e) => e.preventDefault()} 
                              dateFormat="dd/MM/yyyy"
                            />
                          ) : ['SURVEY PLATFORM', 'DP PLATFORM', 'PROJECT CLASSIFICATION', 'RESEARCH GROUP HEAD',
                            'CONTACT PERSON IN RESEARCH', 'ANALYSIS GROUP HEAD', 'CONTACT PERSON IN ANALYSIS',
                            'ADVANCED ANALYTICS', 'CHECK LIST FOLLOWED',
                            'FEEDBACK SURVEY DONE', 'DATA CORRECTION DONE', 'COMPLETED MONTH', 'NORMS DONE', 'STATUS']
                            .includes(item) ? (
                              <Dropdown
            options={dropdownOptions[item]}
            onSelect={(option) => handleDropdownSelect(item, option, index)}
            item={item}
            currentSegment={currentSegment}
            selectedDropdownValues={selectedDropdownValues}
            inputValuesState={inputValuesState}
          />
                              ) : (
                            <input
                              type="text"
                              defaultValue={inputValuesState[currentSegment][item] || ''}
                              onChange={(event) => handleInputChange(event, item)}
                              data-index={columnIndex * 8 + index}
                            />
                          )}
                        </div>
                      </li>
                      ))}
                    </ul>
                  ))}
                </div>
                <div style={{display:'flex', justifyContent:'center'}}>
                {segmentIndex < segmentCount - 1 && (
                  <div style={{display:'flex', justifyContent:'center', gap:'50px'}}>
                  {segmentIndex > 0 && (
                <button className="next-button3" onClick={handlePreviousSegment}>
                  Back
                </button>
              )}
                  <div>
                <button
                  className="next-button3"
                  onClick={handleNextClick}
                  disabled={!mandatoryFields.every((field) => inputValuesState[currentSegment][field] !== '')}
                >
                  Next
                </button>
                </div>
                </div>
              )}
              <div style={{display:'flex', justifyContent:'center'}}>
                {segmentIndex === segmentCount - 1 && (
                  <div style={{display:'flex', justifyContent:'center', gap:'50px'}}>
                  {segmentIndex > 0 && (
                <button className="next-button3" onClick={handlePreviousSegment}>
                  Back
                </button>
              )}
                  <div>
                  <button
                    className="submit-button4"
                    onClick={handleSubmission1}
                    disabled={!mandatoryFields.every((field) => inputValuesState[currentSegment][field] !== '')}
                  >
                    Submit
                  </button>
                  </div>
                  </div>
                )}
              </div>
              </div>
              </div>
            </div>
          ))}
        </div>
      )}
      <br />
    </div>
  );
};

export default Segments;