import React, { useState } from 'react';
import { Container, Row, Col, Button, div, Form, Alert } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
// import queryString from 'query-string';
import './Survey.css';
import { IoIosArrowForward } from 'react-icons/io';
import LogoImg from './4sightlogo.png';
import { nanoid } from 'nanoid';
// import DatePicker from 'react-datepicker';
// import 'react-datepicker/dist/react-datepicker.css';


const questions = [
  { questionText: 'Overall Performance of the DP Or Scripting team' },
  { questionText: 'Quality of the Script' },
  { questionText: 'Timelines of the Script Delivery' },
  { questionText: 'Quality of the Data tables' },
  { questionText: 'Timelines of the Data tables delivery' },
  { questionText: "DP/Scripter's Quality of Communication and Explanation" },
  { questionText: "Availability and Support" },
  { questionText: 'Anything specific you would like to highlight about the Study?', inputField: true },
];

const SurveyDetails = () => {
  const [currentStep, setCurrentStep] = useState(0);
  const [researcherInCharge, setResearcherInCharge] = useState('');
  const [projectName, setProjectName] = useState('');
  const [jobNumber, setJobNumber] = useState('');
  const [year, setYear] = useState('');
  const [month, setMonth] = useState('');
  // const [dpOrScripting, setDpOrScripting] = useState('');
  const [inCharge, setInCharge] = useState('');
  const [inCharge1, setInCharge1] = useState('');
  const [surveyId, setSurveyId] = useState(null);
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [showScore, setShowScore] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [showAlert1, setShowAlert1] = useState(false);
  // const [copiedLink, setCopiedLink] = useState('');
  const [answers, setAnswers] = useState(questions.map(() => ''));
  // const navigate = useNavigate();
  const researcherInChargeId = nanoid();
  const projectNameId = nanoid();
  const jobNumberId = nanoid();
  const yearId = nanoid();
  const monthId = nanoid();
  // const dpOrScriptingId = nanoid();
  // const inChargeId = nanoid();
  // const inCharge1Id = nanoid();
  // const [formData, setFormData] = useState({
  //   name: '',
  //   projectName: '',
  //   jobNumber: '',
  //   year: '',
  //   month: '',
  //   dpOrScripting: '',
  //   DPName: '',
  //   scriptingName: '',
  // });

  // const handleYearChange = (date) => {
  //   if(date){
  //   setYear(date.getFullYear());
  //   }
  // };

  // const handleAnswerOptionClick = (answer) => {
  //   const updatedAnswers = [...answers];
  //   updatedAnswers[currentQuestion] = answer;
  //   setAnswers(updatedAnswers);
  //   handleNextQuestion();
  // };

  const handleNextQuestion = () => {
    const nextQuestion = currentQuestion + 1;
    if (nextQuestion < questions.length) {
      setCurrentQuestion(nextQuestion);
    } else {
      setShowScore(true);
    }
  };

  // const handleInputChange = (e) => {
  //   const updatedAnswers = [...answers];
  //   updatedAnswers[currentQuestion] = e.target.value;
  //   setAnswers(updatedAnswers);
  // };

  // const handleInputSubmit = () => {
  //   if (answers[currentQuestion]) {
  //     handleNextQuestion();
  //     setShowAlert(false);
  //   } else {
  //     setShowAlert(true);
  //   }
  // };

  // const handlePreviousQuestion = () => {
  //   if (currentQuestion === 0) {
  //     setCurrentStep(0);
  //   } else {
  //     setCurrentQuestion(currentQuestion - 1);
  //   }
  // };

  const handleFormSubmit = async () => {
    if (projectName && jobNumber && researcherInCharge && year && month && inCharge && inCharge1) {
      setShowAlert(false);
  
      // Correct the field names to match the backend's expectations
      const formData = {
        researcher_in_charge: researcherInCharge,
        project_name: projectName,
        job_number: jobNumber,
        year: year,
        month: month,
        dp_or_scripting: "Both",
        scripting_in_charge: inCharge,
        dp_in_charge: inCharge1,  // If this is how you're storing the additional inCharge1 in the backend
      };
  
      try {
        const response = await fetch('https://4sightoperations.in/survey/create-survey/', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(formData),  // Send the form data to the backend
        });
  
        if (response.ok) {
          const data = await response.json();
          setSurveyId(data.survey_id); // Store the survey ID from the backend
          // localStorage.setItem('surveyId', data.survey_id); // Optionally, store it in localStorage for later
          setCurrentStep(1); // Move to the next step
        } else {
          const errorData = await response.json();
          console.error('Failed to create survey:', errorData);
        }
      } catch (error) {
        console.error('Error:', error);
      }
    } else {
      setShowAlert(true);
    }
  };

  // const handleFormSubmit = () => {
  //   if (projectName && jobNumber && name && year && month && (DPName || scriptingName)) {
  //     setShowAlert(false);
  //     setCurrentStep(1);
  
  //     const surveyData = {
  //       name,
  //       projectName,
  //       jobNumber,
  //       year,
  //       month,
  //       dpOrScripting,
  //       DPName,
  //       scriptingName,
  //       answers,
  //     };
  
  //     // Send data to the backend
  //     fetch('http://localhost:5000/submit-form', {
  //       method: 'POST',
  //       headers: {
  //         'Content-Type': 'application/json',
  //       },
  //       body: JSON.stringify(surveyData),
  //     })
  //     .then(response => response.text())
  //     .then(data => {
  //       console.log('Success:', data);
  //     })
  //     .catch((error) => {
  //       console.error('Error:', error);
  //     });
  //   } else {
  //     setShowAlert(true);
  //   }
  // };
  
  
  // const handleProceed = () => {
  //   const params = queryString.stringify({
  //     name,
  //     projectName,
  //     jobNumber,
  //     year,
  //     month,
  //     dpOrScripting,
  //     DPName,
  //     scriptingName,
  //   });
  //   navigate(`/satisfactionsurvey?${params}`);
  // };

  const handleCopyLink = () => {
    const link = `${window.location.origin}/satisfactionsurvey/${surveyId}`;
    
    navigator.clipboard.writeText(link);
    alert("Link copied to clipboard!");
  };

  // const currentAnswer = answers[currentQuestion];

  return (
    <Container fluid>
    <div className='background1'></div>
      {currentStep === 0 && (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
        <div style={{width:'100%'}}>
        {/* <div style={{display:'flex', justifyContent:'center', marginTop:'5px'}}>
              {showAlert && (
                <Alert variant="danger" onClose={() => setShowAlert(false)} dismissible>
                  Please Fill Every Field!
                </Alert>
              )}
              </div> */}
          <div style={{ width: '100%', padding: '10px',  marginBottom: '10px'}}>
          <div style={{
            display: 'flex',
            justifyContent: 'space-between',
            backgroundColor: 'rgba(255, 255, 255, 0.8)',
            backdropFilter: 'blur(1px)',
            padding:'5px'
          }}>
          <img src={LogoImg} alt='Img' style={{width:'100px', height:'40px', display:'flex',
           justifyContent:'start'}} />
           <div style={{ position: 'fixed', top: '0px', right: '0px', zIndex: '100' }}>
           {showAlert && (
                <Alert variant="danger" onClose={() => setShowAlert(false)} dismissible style={{display:'flex', 
                justifyContent:'end', alignItems:'end', width:'100%'}}> 
                  Please Fill Every Field!
                </Alert>
              )}
              </div>
              </div>
              <br />
              <div className='formFrom' style={{width:'60%', margin:'auto',
               padding:'20px 0px 30px 40px', borderRadius:'10px', backgroundColor: 'rgba(255, 255, 255, 0.8)'}}>
              <Form>
                <Form.Group as={Row} controlId="formName">
                  <Form.Label column sm="3" style={{
                  fontFamily: 'Roboto, sans-serif', fontSize:'15px', color:'black'}}>
                  Researcher In Charge:</Form.Label>
                  <Col sm="5" style={{margin:'auto'}}>
                    <Form.Control
                      as="select"
                      value={researcherInCharge}
                      id={researcherInChargeId}
                      onChange={(e) => setResearcherInCharge(e.target.value)}
                      style={{ fontFamily: 'Roboto, sans-serif', border:'1px solid #aaa', borderRadius:'10px'}}
                    >
                      <option value hidden>Select a Researcher</option>
                      <option value="Ananya">Ananya</option>
                      <option value="Aruna">Aruna</option>
                      <option value="Leena">Leena</option>
                      <option value="Manoj K">Manoj K</option>
                      <option value="Rita">Rita</option>
                      <option value="Sonal">Sonal</option>
                      <option value="Elias">Elias</option>
                      <option value="Tarek">Tarek</option>
                      <option value="Ismail">Ismail</option>
                      <option value="Nour">Nour</option>
                      <option value="Muhammed">Muhammed</option>
                      <option value="Ashish">Ashish</option>
                      <option value="Manoj I">Manoj I</option>
                      <option value="Krati">Krati</option>
                      <option value="Kunal M">Kunal M</option>
                      <option value="Vinivaa">Vinivaa</option>
                      <option value="Priya">Priya</option>
                      <option value="Sharanya">Sharanya</option>
                      <option value="Pulkit">Pulkit</option>
                      <option value="Benard">Benard</option>
                      <option value="Caroline">Caroline</option>
                      <option value="Tanuj Gupta">Tanuj Gupta</option>
                      <option value="Mini">Mini</option>
                      <option value="Mir Ahmed">Mir Ahmed</option>
                      <option value="Sayanti Bose">Sayanti Bose</option>
                      <option value="Samrat Gaikwad">Samrat Gaikwad</option>
                      <option value="Abdullah Wael">Abdullah Wael</option>
                      <option value="Nisar Ahmed Khan">Nisar Ahmed Khan</option>
                      <option value="Vivek Roy">Vivek Roy</option>
                      <option value="Amin Abdullah">Amin Abdullah</option>
                      <option value="Haya Alajlan">Haya Alajlan</option>
                      <option value="Khurram Khowaja">Khurram Khowaja</option>
                      <option value="Liz Berks">Liz Berks</option>
                      <option value="Asif Khan">Asif Khan</option>
                      <option value="Qusai Alhudhud">Qusai Alhudhud</option>
                      <option value="Jones Joseph">Jones Joseph</option>
                      <option value="Shamir Jha">Shamir Jha</option>
                      <option value="Tarun Panda">Tarun Panda</option>
                      <option value="Anindya Gupta">Anindya Gupta</option>
                      <option value="Anirudh Anand">Anirudh Anand</option>
                      <option value="Kannan Nadar">Kannan Nadar</option>
                      <option value="Srutaban Bhowmick">Srutaban Bhowmick</option>
                      <option value="Abu Talha">Abu Talha</option>
                      <option value="Anjan Lahiri">Anjan Lahiri</option>
                      <option value="Dola DasGupta Lahiri">Dola DasGupta Lahiri</option>
                      <option value="Sana Syed">Sana Syed</option>
                      <option value="External Vendor">External Vendor</option>
                    </Form.Control>
                  </Col>
                </Form.Group>
                <Form.Group as={Row} controlId="formProjectName" className="mt-3">
                <Form.Label column sm="3" style={{ borderRadius:'5px', 
                  fontFamily: 'Roboto, sans-serif', fontSize:'15px', color:'black'}}>
                  Project Name:</Form.Label>
                  <Col sm="5" style={{margin:'auto'}}>
                    <Form.Control
                      type="text"
                      placeholder="Enter Project Name"
                      value={projectName}
                      id={projectNameId}
                      onChange={(e) => setProjectName(e.target.value)}
                      style={{ fontFamily: 'Roboto, sans-serif', border:'1px solid #aaa', borderRadius:'10px'}}
                    />
                  </Col>
                </Form.Group>
                <Form.Group as={Row} controlId="formJobNumber" className="mt-3">
                <Form.Label column sm="3" style={{
                  fontFamily: 'Roboto, sans-serif', fontSize:'15px', color:'black'}}>
                  Project Number:</Form.Label>
                  <Col sm="5" style={{margin:'auto'}}>
                    <Form.Control
                      type="text"
                      placeholder="Enter Project Number"
                      value={jobNumber}
                      id={jobNumberId}
                      onChange={(e) => setJobNumber(e.target.value)}
                      style={{fontFamily: 'Roboto, sans-serif', border:'1px solid #aaa', borderRadius:'10px'}}
                    />
                  </Col>
                </Form.Group>
                <Form.Group as={Row} controlId="formYear" className="mt-3">
                <Form.Label column sm="3" style={{
                  fontFamily: 'Roboto, sans-serif', fontSize:'15px', color:'black'}}>
                  Year:</Form.Label>
                  <Col sm="5" style={{margin:'auto'}}>
                    <Form.Control
                      type="text"
                      placeholder="Enter Year"
                      value={year}
                      id={yearId}
                      onChange={(e) => {
                        const inputValue = e.target.value;
                        const filteredValue = inputValue.replace(/[^0-9]/g, '').slice(0,4);
                        setYear(filteredValue);
                      }}
                      style={{fontFamily: 'Roboto, sans-serif', border:'1px solid #aaa', borderRadius:'10px'}}
                    />
                  </Col>
                </Form.Group>
                <Form.Group as={Row} controlId="formMonth" className="mt-3">
                  <Form.Label column sm="3" style={{
                  fontFamily: 'Roboto, sans-serif', fontSize:'15px', color:'black'}}>
                  Month:</Form.Label>
                  <Col sm="5" style={{margin:'auto'}}>
                    <Form.Control
                      as="select"
                      value={month}
                      id={monthId}
                      onChange={(e) => setMonth(e.target.value)}
                      style={{ fontFamily: 'Roboto, sans-serif', border:'1px solid #aaa', borderRadius:'10px'}}
                    >
                      <option value hidden>Select a Month</option>
                      <option value="January">January</option>
                      <option value="February">February</option>
                      <option value="March">March</option>
                      <option value="April">April</option>
                      <option value="May">May</option>
                      <option value="June">June</option>
                      <option value="July">July</option>
                      <option value="August">August</option>
                      <option value="September">September</option>
                      <option value="October">October</option>
                      <option value="November">November</option>
                      <option value="December">December</option>
                    </Form.Control>
                  </Col>
                </Form.Group>
                <Form.Group as={Row} controlId="formScriptingName" className="mt-3">
                  <Form.Label column sm="3" style={{ fontFamily: 'Roboto, sans-serif', fontSize: '15px', color: 'black' }}>
                    Scripting in-Charge:
                  </Form.Label>
                  <Col sm="5" style={{margin:'auto'}}>
                    <Form.Control
                      as="select"
                      value={inCharge}
                      onChange={(e) => setInCharge(e.target.value)}
                      style={{ fontFamily: 'Roboto, sans-serif', border: '1px solid #aaa', borderRadius: '10px' }}
                    >
                      <option value="" hidden>Select Scripting In-Charge</option>
                      <option value="Ramamoorthy Karuppan">Ramamoorthy Karuppan</option>
                      <option value="Naga Jyothi">Naga Jyothi</option>
                      <option value="Anuradha">Anuradha</option>
                      <option value="Saravana Kumar">Saravana Kumar</option>
                      <option value="Varun">Varun</option>
                      <option value="Raghu Ram">Raghu Ram</option>
                      <option value="Sri Vendhan">Sri Vendhan</option>
                      <option value="Satheeshkumar">Satheeshkumar</option>
                      <option value="Karuppasamy">Karuppasamy</option>
                      <option value="Joseph Arul Selvam">Joseph Arul Selvam</option>
                      <option value="Mohanasundaram">Mohanasundaram</option>
                      <option value="Reavanth">Reavanth</option>
                      <option value="Ragul">Ragul</option>
                      <option value="External Vendor">External Vendor</option>
                      <option value="Not Applicable">Not Applicable</option>
                    </Form.Control>
                  </Col>
                </Form.Group>
                <Form.Group as={Row} controlId="formDPName" className="mt-3">
                  <Form.Label column sm="3" style={{ fontFamily: 'Roboto, sans-serif', fontSize: '15px', color: 'black' }}>
                    DP in-Charge:
                  </Form.Label>
                  <Col sm="5" style={{margin:'auto'}}>
                    <Form.Control
                      as="select"
                      value={inCharge1}
                      onChange={(e) => setInCharge1(e.target.value)}
                      style={{ fontFamily: 'Roboto, sans-serif', border: '1px solid #aaa', borderRadius: '10px' }}
                    >
                      <option value="" hidden>Select DP In-Charge</option>
                      <option value="Ramamoorthy Karuppan">Ramamoorthy Karuppan</option>
                      <option value="Naga Jyothi">Naga Jyothi</option>
                      <option value="Anuradha">Anuradha</option>
                      <option value="Saravana Kumar">Saravana Kumar</option>
                      <option value="Varun">Varun</option>
                      <option value="Raghu Ram">Raghu Ram</option>
                      <option value="Sri Vendhan">Sri Vendhan</option>
                      <option value="Satheeshkumar">Satheeshkumar</option>
                      <option value="Karuppasamy">Karuppasamy</option>
                      <option value="Joseph Arul Selvam">Joseph Arul Selvam</option>
                      <option value="Mohanasundaram">Mohanasundaram</option>
                      <option value="Reavanth">Reavanth</option>
                      <option value="Ragul">Ragul</option>
                      <option value="External Vendor">External Vendor</option>
                      <option value="Not Applicable">Not Applicable</option>
                    </Form.Control>
                  </Col>
                </Form.Group>
                {/* <Form.Group as={Row} controlId="formWork" className="mt-3">
                <Form.Label column sm="3" style={{
                  fontFamily: 'Roboto, sans-serif', fontSize:'15px', color:'black'}}>
                  Type Of Work:</Form.Label>
                  <Col sm="5" style={{margin:'auto'}}>
                    <Row className='mt-1'>
                      <Col>
                        <Form.Check 
                          type="radio"
                          label="DP"
                          name="dpOrScripting"
                          value="DP"
                          id={inChargeId}
                          checked={dpOrScripting === 'DP'}
                          onChange={(e) => setDpOrScripting(e.target.value)}
                          style={{fontFamily: 'Roboto, sans-serif'}}
                        />
                      </Col>
                      <Col>
                        <Form.Check 
                          type="radio"
                          label="Scripting"
                          name="dpOrScripting"
                          value="Scripting"
                          id={inCharge1Id}
                          checked={dpOrScripting === 'Scripting'}
                          onChange={(e) => setDpOrScripting(e.target.value)}
                          style={{fontFamily: 'Roboto, sans-serif'}}
                        />
                      </Col>
                    </Row>
                  </Col>
                </Form.Group>
                {dpOrScripting && (
                  <Form.Group as={Row} controlId={`form${dpOrScripting}Name`} className="mt-3">
                  <Form.Label column sm="3" style={{
                  fontFamily: 'Roboto, sans-serif', fontSize:'15px', color:'black'}}>
                    {dpOrScripting} In Charge:</Form.Label>
                    <Col sm="5" style={{margin:'auto'}}>
                      <Form.Control
                        as="select"
                        placeholder={`Enter ${dpOrScripting} In Charge`}
                        id={dpOrScriptingId}
                        value={
                          dpOrScripting ===  'DP' ? inCharge : inCharge1
                        }
                        onChange={(e) => dpOrScripting === 'DP' ? setInCharge(e.target.value) : 
                        setInCharge1(e.target.value)}
                        style={{ fontFamily: 'Roboto, sans-serif', color:'black', border:'1px solid #aaa',
                        borderRadius:'10px'}}
                      >
                      <option value hidden>Select {dpOrScripting} In Charge</option>
                      <option value="Anuradha">Anuradha</option>
                      <option value="Saravana Kumar">Saravana Kumar</option>
                      <option value="Varun">Varun</option>
                      <option value="Raghu Ram">Raghu Ram</option>
                      <option value="Sri Vendhan">Sri Vendhan</option>
                      </Form.Control>
                    </Col>
                  </Form.Group> */}
                {/* )} */}
                <div>
                </div>
              </Form>
              </div>
              <hr  />
              <div className="d-flex justify-content-end mt-2">
                    <Button className='fontButton' onClick={handleFormSubmit}
                     style={{fontFamily: 'Roboto, sans-serif', borderRadius:'10px'}}>Proceed
                     <span className='icon'>
                      <IoIosArrowForward />
                      </span>
                     </Button>
                  </div>
          </div>
        </div>
        </div>
      )}
      {currentStep === 1 && surveyId && (
        <div>
        <div style={{display:'flex', justifyContent:'center', alignItems:'center'}}>
        <div className='formFrom' style={{
            backgroundColor: 'rgba(255, 255, 255, 0.8)',
            backdropFilter: 'blur(1px)',
             marginTop:'200px', width:'auto',
             padding:'10px 10px 10px 10px'
          }}>
        <Form>
        <div style={{display:'flex', justifyContent:'center', alignItems:'center'}}>
        <h3 style={{color:'#508C9B', fontFamily: 'Roboto, sans-serif'}}>Link:</h3>
        </div>
        <div style={{display:'flex', justifyContent:'space-around', alignItems:'center', gap:'10px'}}>
                    <Form.Group controlId="linkInput">
                    <a
              className='anchorTag'
              href={`${window.location.origin}/satisfactionsurvey/${surveyId}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <Form.Group controlId="linkInput">
                    <Form.Control
                      type="text"
                      value={`${window.location.origin}/satisfactionsurvey/${surveyId}`}
                      readOnly
                      style={{ border: '1px solid #aaa', fontFamily: 'Roboto, sans-serif', borderRadius: '10px', width: '300px', cursor: 'pointer' }}
                    />
                  </Form.Group>
                        </a>
                    </Form.Group>
                    <Button className='copyButton' variant="outline-secondary" onClick={handleCopyLink}
                     style={{fontFamily: 'Roboto, sans-serif', borderRadius:'20px'}}>
                        Copy Link
                    </Button>
                    </div>
                    </Form>
                    </div>
                 </div>
                 <div style={{display:'flex', justifyContent:'center', marginTop:'10px'}}>
                 {showAlert1 && (
                      <h6 style={{backgroundColor:'#95D2B3', color:'white', width:'100px', padding:'5px',
                      display:'flex', justifyContent:'center', alignItems:'center', borderRadius:'5px',
                      fontFamily: "'Poiret One', cursive"}}>
                      Copied!</h6>
                    )}
                    </div>
                    {/* <div style={{display:'flex', justifyContent:'center', marginTop:'10px'}}>
                    <Button className='fontButton' style={{fontFamily: 'Roboto, sans-serif',
                 borderRadius:'10px'}}
                 onClick={handleProceed}>Proceed To Survey</Button>
                 </div> */}
        </div>
      )}
    </Container>
  );
};

export default SurveyDetails;
