import React from 'react';
import {BrowserRouter, Routes, Route} from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import Awn1 from './Awn1';
import Operations from './Operations';
import SurveyDetails from './SurveyDetails';
import Survey from './Survey';
import './App.css';
import NaviBar from './Navibar';
import Calendar from './Calendar';
import Conclusion from './Conclusion';
import DisplayTable from './DisplayTable';
import ProjectList from './ProjectList';
import SegView from './SegView';


function App() {
  return (
    <div className="App">
    <div className='background'></div>
      <BrowserRouter>
      <Routes>
      <Route path="/" element={<Operations />} />
         <Route path="/surveyDetails" element={<SurveyDetails />} />
         <Route path="/projectList" element={<ProjectList />} />
         <Route path="/satisfactionsurvey/:surveyId" element={<Survey />} />
         <Route path="/awn" element={<Awn1 />} />
         {/* <Route path = "/satisfactionsurvey" element={<Survey />} /> */}
         <Route path = "/navibar" element={<NaviBar />} />
         <Route path = "/calendar" element={<Calendar />} />
         <Route path = "/conclusion" element={<Conclusion />} />
         <Route path = "/segView" element={<SegView />} />
      </Routes>
    </BrowserRouter>
    </div>
  );
}

export default App;
