import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom'; // Import useParams to get survey_id from URL
import { Container, Button, Card, Form, Table, Alert, Modal } from 'react-bootstrap';
import { Transition } from 'react-transition-group';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleLeft, faAngleRight } from '@fortawesome/free-solid-svg-icons';
import './Survey.css';
// import { IoIosArrowForward } from 'react-icons/io';
import LogoImg from './4sightlogo.png';

// Survey Questions
const questions = [
  { questionText: 'Overall Performance of the DP Or Scripting team' },
  { questionText: 'Quality of the Script' },
  { questionText: 'Timelines of the Script Delivery' },
  { questionText: 'Quality of the Data Tables' },
  { questionText: 'Timelines of the Data Tables Delivery' },
  { questionText: "DP/Scripter's Quality of Communication and Explanation" },
  { questionText: "Availability and Support" },
  { questionText: 'Anything Specific you would like to Highlight about the Study?', inputField: true },
];

const SurveyPerformance = () => {
  const [currentStep, setCurrentStep] = useState(0);
  const { surveyId } = useParams(); // Extract survey_id from the URL
  const [currentQuestion, setCurrentQuestion] = useState(0); // Track the current question
  const [showScore, setShowScore] = useState(false); // Show the Thank You screen after submission
  const [showBlob, setShowBlob] = useState(false);
  const [showPass, setShowPass] = useState(false);
  const [blob, setBlob] = useState(null); 
  const [pass, setPass] = useState('');
  const [answers, setAnswers] = useState(questions.map(() => '')); // Store user answers
  const [surveyData, setSurveyData] = useState({});
  const [showAlert, setShowAlert] = useState(false);
  const currentAnswer = answers[currentQuestion];
  const [filteredQuestions, setFilteredQuestions] = useState([]);
  // const navigate = useNavigate();

  useEffect(() => {
    const fetchSurveyData = async () => {
      try {
        const response = await fetch(` https://4sightoperations.in/survey/survey/${surveyId}/`);
        const data = await response.json();
        setSurveyData(data);
        console.log(data);
      } catch (error) {
        console.error(error);
      }
    };
    fetchSurveyData();
  }, [surveyId]);

  useEffect(() => {
    let filteredQuestions = [];
    if (surveyData.scripting_in_charge !== 'Not Applicable' || surveyData.dp_in_charge !== 'Not Applicable') {
      filteredQuestions = questions.filter((question) => {
        if (question.questionText === 'Quality of the Script' || question.questionText === 'Timelines of the Script Delivery') {
          return surveyData.scripting_in_charge !== 'Not Applicable';
        }
        if (question.questionText === 'Quality of the Data Tables' || question.questionText === 'Timelines of the Data Tables Delivery') {
          return surveyData.dp_in_charge !== 'Not Applicable';
        }
        return true;
      });
    }
    setFilteredQuestions(filteredQuestions);
  }, [surveyData]);

  // Handle submitting survey responses
  const handleSubmit = async () => {
    if (!surveyId) {
      console.error("Cannot submit responses. Survey ID is missing.");
      return;
    }
  
    const responses = questions.map((question, index) => {
      const filteredIndex = filteredQuestions.findIndex((q) => q.questionText === question.questionText);
      const response = filteredIndex !== -1 ? answers[filteredIndex] : '';
      return { Question: `Q${index + 1}`, Response: response };
    });
  
    const tableData = [
      {
        "Researcher In Charge": surveyData.researcher_in_charge,
        "Project Name": surveyData.project_name,
        "Job Number": surveyData.job_number,
        "Year": surveyData.year,
        "Month": surveyData.month,
      },
      {
        "Scripting In Charge": surveyData.scripting_in_charge,
        "DP In Charge": surveyData.dp_in_charge,
      },
    ];
  
    try {
      const response = await fetch(`https://4sightoperations.in/survey/survey/${surveyId}/submit-responses/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ responses }),
      });
  
      if (!response.ok) {
        throw new Error('Network response was not OK');
      }
  
      const result = await response.json();
      console.log('Successfully Submitted:', result);
  
      const text = `
        Survey ID: ${surveyId}
        Researcher In Charge: ${surveyData.researcher_in_charge}
        Project Name: ${surveyData.project_name}
        Job Number: ${surveyData.job_number}
        Year: ${surveyData.year}
        Month: ${surveyData.month}
        Scripting In Charge: ${surveyData.scripting_in_charge}
        DP In Charge: ${surveyData.dp_in_charge}
  
        Responses:
        ${responses.map((response, index) => `Q${index + 1}: ${response.Response}`).join('\n')}
      `;
  
      const blob = new Blob([text], { type: 'text/plain' });
  
      setBlob(blob);
      setShowBlob(true);
    } catch (error) {
      console.error('Error in Submitting:', error);
    }
  };

  const handleNextQuestion = () => {
    const nextQuestion = currentQuestion + 1;
    if (nextQuestion < questions.length) {
      setCurrentQuestion(nextQuestion);
    } else {
      
      handleSubmit();
    }
  };

  const handleAnswerOptionClick = (answer) => {
    const updatedAnswers = [...answers];
    updatedAnswers[currentQuestion] = answer;
    setAnswers(updatedAnswers);
    handleNextQuestion();
  };

  const handleFormSubmit = () => {
    setCurrentStep(1);
  }

  // const handleInputChange = (e) => {
  //   const updatedAnswers = [...answers];
  //   updatedAnswers[currentQuestion] = e.target.value;
  //   setAnswers(updatedAnswers);
  // };

  const handleInputSubmit = () => {
    if (answers[currentQuestion]) {
      handleNextQuestion();
      setShowAlert(false);
      handleSubmit();
    } else {
      setShowAlert(true);
    }
  };

  const handlePreviousQuestion = () => {
    if (currentQuestion === 0) {
      setCurrentStep(0);
    } else {
      setCurrentQuestion(currentQuestion - 1);
    }
  };

  const handleDownload = () => {
    setShowPass(true);
    setShowBlob(false);
  };


  const handleShowScore = () => {
    setShowScore(true);
    setShowBlob(false);
  };

  const handlePass = () => {
    setShowPass(true);
    if (pass === "4sightglobal") {
      const url = URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = `Survey Data.txt`;
      a.click();
  
      setShowPass(false);
      setShowBlob(false);
      setShowScore(true);
    } else {
      alert("Invalid Password");
    }
  };

  return (
    <Container fluid>
    <div className='background1'></div>
    {showBlob ? (
      <div className="score-section">
          <div className="d-flex justify-content-center">
            <div className='formFrom' style={{ width: '500px', height: '60%', alignItems: 'center', marginTop: '200px',
              backgroundColor: 'rgba(255, 255, 255, 0.8)', backdropFilter: 'blur(4px)', padding: '50px' }}>
              <h5 style={{ fontSize: '25px', display: 'flex', justifyContent: 'center', alignItems: 'center',
                color: 'black', fontFamily: 'Roboto, sans-serif' }}>
                Download Data?
              </h5>
              <hr />
              <br />
              <div style={{display:'flex', justifyContent:'space-around'}}>
              <Button variant='outline-success'onClick={() => setShowPass(true)}>Download</Button>
              <Button variant='outline-secondary' onClick={handleShowScore}>Skip</Button>
              </div>
              {showPass && (
    <Modal show={showPass} onHide={() => setShowPass(false)} style={{padding:'50px'}}>
      <Modal.Header closeButton>
        <Modal.Title>Password</Modal.Title>
      </Modal.Header>
      <Modal.Body style={{padding:'20px'}}>
        <Form>
          <Form.Group controlId="formBasicPassword">
            <Form.Label>Enter Password:</Form.Label>
            <Form.Control
              type="password"
              value={pass}
              onChange={(e) => setPass(e.target.value)}
            />
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer style={{display:'flex', justifyContent:'space-between'}}>
        <Button variant='success' onClick={handlePass}>Ok</Button>
        <Button variant='secondary' onClick={() => setShowPass(false)}>Cancel</Button>
      </Modal.Footer>
    </Modal>
  )}
            </div>
          </div>
        </div>
    ) : 
      showScore ? (
        <div className="score-section">
          <div className="d-flex justify-content-center">
            <div className='formFrom' style={{ width: '30%', height: '60%', alignItems: 'center', marginTop: '200px',
              backgroundColor: 'rgba(255, 255, 255, 0.8)', backdropFilter: 'blur(4px)', padding: '50px' }}>
              
                <div>
              <h5 style={{ fontSize: '35px', display: 'flex', justifyContent: 'center', alignItems: 'center',
                color: 'black', fontFamily: 'Roboto, sans-serif' }}>
                Thank You
              </h5>
              <h5 style={{ display: 'flex', justifyContent: 'center', alignItems: 'center',
                color: 'black', fontFamily: 'Roboto, sans-serif', fontSize: '16px' }}>
                Your Responses are Recorded!
              </h5>
              </div>
              
            </div>
          </div>
        </div>
      ) : (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
        {currentStep === 0 ? (
          <div style={{ width: '100%', padding: '10px'}}>
          <div style={{
            display: 'flex',
            justifyContent: 'space-between',
            backgroundColor: 'rgba(255, 255, 255, 0.6)',
            backdropFilter: 'blur(1px)',
            padding:'5px'
          }}>
            <img src={LogoImg} alt='Img' style={{width:'100px', height:'40px', display:'flex',
           justifyContent:'start'}} />
                {/* <h4 className="mb-0" style={{ display: 'flex', justifyContent: 'center',
               alignItems: 'center', fontFamily:'sans-serif', color:'#508C9B' }}>
                  Survey Information
                </h4> */}
                </div>
                <div style={{marginTop:'50px'}}>
          <div className='formFrom'  style={{width:'80%', margin:'auto',  borderRadius:'20px', padding:'20px',
          backgroundColor: 'rgba(255, 255, 255, 0.8)'}}>
        <Table className='surveyTable'  bordered hover style={{borderRadius:'50px'}}>
            <tbody>
              <tr>
                <td>Researcher in Charge</td>
                <td>{surveyData.researcher_in_charge}</td>
              </tr>
              <tr>
                <td>Project Name</td>
                <td>{surveyData.project_name}</td>
              </tr>
              <tr>
                <td>Job Number</td>
                <td>{surveyData.job_number}</td>
              </tr>
              <tr>
                <td>Year</td>
                <td>{surveyData.year}</td>
              </tr>
              <tr>
                <td>Month</td>
                <td>{surveyData.month}</td>
              </tr>
              <tr>
                <td>Scripting in Charge</td>
                <td>{surveyData.scripting_in_charge}</td>
              </tr>
              <tr>
                <td>DP in Charge</td>
                <td>{surveyData.dp_in_charge}</td>
              </tr>
            </tbody>
          </Table>
          <div style={{display:'flex', justifyContent:'center', alignItems:'center'}}>
          <Button className='fontButton' onClick={handleFormSubmit}>
            Continue
          </Button>
          </div>
          </div>
          </div>
          </div>
          ) : (
            filteredQuestions.map((question, index) => (
              <Transition key={index} in={index === currentQuestion} timeout={500} classNames="cards">
                <Card className='cardCard'  key={index} style={{ width: '60%', padding: '10px', borderRadius: '10px',
                 animation: 'zoomIn 0.5s', marginBottom: '20px', display: index === currentQuestion ?
                  'block' : 'none', backgroundColor: 'rgba(255, 255, 255, 0.8)', marginTop: '130px', 
                  backdropFilter: 'blur(4px)', border:'1px solid grey' }}>
                   <Card.Body>
                    {!question.inputField && (
                      <>
                        <Card.Text className="mb-4 mt-2" style={{ display: 'flex', justifyContent: 'center', 
                        alignItems: 'center', fontSize: '25px', color: '#000554',
                         fontFamily: 'Roboto, sans-serif' }}>
                          {question.questionText}
                        </Card.Text>
                        <hr style={{color: '#000554'}}/>
                        <div className="row" style={{ display: 'flex', justifyContent: 'space-evenly', 
                        fontFamily: 'Roboto, sans-serif' }}>
                        {['Excellent', 'Good', 'Average', 'Poor', 'Very Poor'].map((answer, answerIndex) => (
                            <div className="col-6 col-sm-4 col-md-2" key={answerIndex}>
                            <Button
                                className="m-2  ratingbutton"
                                onClick={() => handleAnswerOptionClick(answer)}
                                style={{ height: 'auto',
                                backgroundColor: currentAnswer === answer ? '#0C359E' : '',
                                color: currentAnswer === answer ? 'white' : '',
                                border:'1px solid #0C359E'}}>
                                {answer}
                            </Button>
                            </div>
                        ))}
                        </div>
                        <div className="mt-4">
                            <div>
                              <div style={{display:'flex', justifyContent:'space-between'}}>
                              <Button
                                onClick={handlePreviousQuestion}
                                className="m-2 fontButton1"
                              >
                                <FontAwesomeIcon icon={faAngleLeft} />
                              </Button>
                              {currentAnswer && (
                             
                                <Button onClick={handleNextQuestion} className="m-2 fontButton1">
                                <FontAwesomeIcon icon={faAngleRight} />
                                </Button>
                            )}
                            </div>
                            </div>
                        </div>
                      </>
                    )}
                    {question.inputField && (
                      <div> 
                      <Card.Text className="mb-3 mt-2" style={{ display: 'flex', justifyContent: 'center', 
                        alignItems: 'center', fontSize: '25px', color: '#000554',
                         fontFamily: 'Roboto, sans-serif' }}>
                          {question.questionText}
                        </Card.Text>
                        <hr style={{color: '#000554'}}/>
                        {showAlert && (
                        <Alert variant="danger" onClose={() => setShowAlert(false)} dismissible 
                        style={{width:'40%', margin:'auto'}}>
                          Please Enter Your Response!
                        </Alert>
                      )}
                        <Form.Group controlId="inputField">
                        <Form.Control
                          as="textarea"
                          placeholder="Enter Your Response"
                          value={answers[currentQuestion]}
                          onChange={(e) => {
                            const updatedAnswers = [...answers];
                            updatedAnswers[currentQuestion] = e.target.value;
                            setAnswers(updatedAnswers);
                          }}
                          className="mt-4"
                          style={{ fontFamily: 'Roboto, sans-serif', height: '100px', resize: 'none', border: '1px solid #B5C0D0' }}
                        />
                        </Form.Group>
                          <div className="d-flex justify-content-center mt-2">
                            <Button  className="mt-2 fontButton" onClick={handleInputSubmit}
                             style={{fontFamily: 'Roboto, sans-serif'}}>
                              Submit
                            </Button>
                          </div>
                   
                        <div className="mt-3">
                          {currentQuestion !== 0 && (
                            <div>
                              <Button
                                onClick={handlePreviousQuestion}
                                className="m-1 fontButton1"
                              >
                                <FontAwesomeIcon icon={faAngleLeft} />
                              </Button>
                            </div>
                          )}
                        </div>
                      </div>
                    )}
                  </Card.Body>
                </Card>
              </Transition>
            ))
          )}
        </div>
      
    )}
    </Container>
  );
};

export default SurveyPerformance;
