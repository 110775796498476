import React, { useState, useEffect } from 'react';
import { Table, Button, Modal } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faEye } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';
import './ProjectList.css';
import InputValuesTable from './InputValuesTable';
import moment from 'moment';

const ProjectList = () => {
  const [projectNames, setProjectNames] = useState([]);
  const [inputValues, setInputValues] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchProjectNames = async () => {
      try {
        const response = await fetch('http://localhost:8000/api/project-names/');
        if (!response.ok) throw new Error('Network response was not ok');
        const data = await response.json();
        const projectNames = data.map(([projectName, jobNumber, unit, year, month, last_segment_number,
           expectedDateOfOutput, segmentNumber,     client,
           completed_month,
            data_correction_done,
            status,
            research_group_head,
            contact_person_in_research,
            analysis_group_head,
            contact_person_in_analysis,
            actual_final_output,
            check_list_followed,
            feedback_survey_done,
            remarks,
            archive_taken,
            loi,
            number_of_response,
            oe_costing,
            oe_coding,
            top_lines,
            fw_end_date,
            fw_start_date,
            ap_received_date,
            questionnaire_received_date,
            top_lines_date,
            final_r_awn,
            date_of_r_awn1,
            date_of_awn,
            final_processed_samples,
            achieved_sample_size,
            actual_sample_size,
            advanced_analytics,
            norms_done,
            survey_platform,
            dp_platform,
            project_classification, segment_name]) => ({ projectName, jobNumber,
           unit, month, year, last_segment_number, expectedDateOfOutput, segmentNumber,     client,
           completed_month,
            data_correction_done,
            status,
            research_group_head,
            contact_person_in_research,
            analysis_group_head,
            contact_person_in_analysis,
            actual_final_output,
            check_list_followed,
            feedback_survey_done,
            remarks,
            archive_taken,
            loi,
            number_of_response,
            oe_costing,
            oe_coding,
            top_lines,
            fw_end_date,
            fw_start_date,
            ap_received_date,
            questionnaire_received_date,
            top_lines_date,
            final_r_awn,
            date_of_r_awn1,
            date_of_awn,
            final_processed_samples,
            achieved_sample_size,
            actual_sample_size,
            advanced_analytics,
            norms_done,
            survey_platform,
            dp_platform,
            project_classification, segment_name}));
        setProjectNames(projectNames);
      } catch (error) {
        console.error('Error fetching project names:', error);
      }
    };

    fetchProjectNames();
  }, []);

  const handleEdit = async (projectName) => {
    try {
      const response = await fetch(`http://localhost:8000/api/segments/${projectName}/`);
      if (!response.ok) throw new Error('Network response was not ok');
      
      const segments = await response.json();
      console.log('Fetched segments:', segments); // Debugging log
  
      if (segments.length === 0) {
        console.error('No segments found for this project.');
        return; // Early return if no segments
      }
  
      const inputData = [
        segments[0].unit || '',
        segments[0].year || '',
        segments[0].month || '',
        segments[0].project_name || '',
        segments[0].job_number || '',
        segments[0].client || '',
        segments.length,
      ];
  
      const inputValuesState = segments.map((segment) => {
        const segmentValues = {};
        Object.keys(segment).forEach((key) => {
          if (key.includes('DATE')) {
            const date = moment(segment[key]);
            segmentValues[key] = date.isValid() ? date.toDate() : null; // Handle invalid dates
          } else {
            segmentValues[key] = segment[key];
          }
        });
        return segmentValues;
      });
  
      navigate('/navibar', { state: { inputValues: inputData, segments: inputValuesState, isEditing: true  } });
    } catch (error) {
      console.error('Error fetching input values:', error);
    }
  };

  const handleShowSegments = () => {
    navigate('/navibar');
  };

  const handleView = (project) => {
    navigate('/segView', { state: { project } });
  };

  return (
    <div>
      <div className='tableEffect' style={{ width: '95%', margin: 'auto'}}>
        <Table  bordered hover style={{ border: '1px solid grey', marginTop: '20px', fontFamily: "'Montserrat', sans-serif",
        borderRadius:'10px', height:'80vh' }}>
          <thead>
            <tr>
              {/* <th style={{ color: 'white', padding: '20px', backgroundColor: '#73b3b2' }}>S.NO</th> */}
              <th style={{ color: 'white', padding: '11px', backgroundColor: '#73b3b2' }}>UNIT</th>
              <th style={{ color: 'white', padding: '11px', backgroundColor: '#73b3b2' }}>YEAR</th>
              <th style={{ color: 'white', padding: '11px', backgroundColor: '#73b3b2' }}>MONTH</th>
              <th style={{ color: 'white', padding: '11px', backgroundColor: '#73b3b2' }}>PROJECT NAMES</th>
              <th style={{ color: 'white', padding: '11px', backgroundColor: '#73b3b2' }}>JOB NUMBER</th>
              <th style={{ color: 'white', padding: '11px', backgroundColor: '#73b3b2' }}>SEGMENT NUMBER</th>
              <th style={{ color: 'white', padding: '11px', backgroundColor: '#73b3b2' }}>EXPECTED DATE OF OUTPUT</th>
              <th style={{ color: 'white', padding: '11px', backgroundColor: '#73b3b2' }}>VIEW</th>
              <th style={{ color: 'white', padding: '11px', backgroundColor: '#73b3b2' }}>EDIT</th>
            </tr>
          </thead>
          <tbody>
            {projectNames.map((project, index) => (
              <tr key={index}>
                {/* <td>{index + 1}</td> */}
                <td>{project.unit}</td>
                <td>{project.year}</td>
                <td>{project.month}</td>
                <td>{project.projectName}</td>
                <td>{project.jobNumber}</td>
                <td>{project.segmentNumber}</td>
                <td>{project.expectedDateOfOutput}</td>
                <td>
                  <FontAwesomeIcon
                    icon={faEye}
                    style={{ cursor: 'pointer', color: '#3c979f' }}
                    onClick={() => handleView(project)} 
                  />
                </td>
                <td>
                  <FontAwesomeIcon
                    icon={faEdit}
                    style={{ cursor: 'pointer', color: '#3c979f' }}
                    onClick={() => handleEdit(project.projectName)} 
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
        <Button className='fontButton' onClick={handleShowSegments}>Create New !</Button>
      </div>
      <br />
    </div>
  );
};

export default ProjectList;